import domain from './images/domain.svg';
import mng from './images/mng.svg';
import https from './images/https.svg';
import developer from './images/developer.svg';

// import { Link } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

function Contents() {
  return (
    <div className="container">
        <div className="row mt-5 home-cntnt">
            <div className="col-md text-center">
                <img className="img-mng" src={mng} alt="arab hosting cpanel" />
                <h3>cPanel</h3>
                <p>cPanel web hosting makes website <br /> administration a very easy job.</p>
            </div>
            <div className="col-md text-center">
                <img className="img-mng" src={domain} alt="arab hosting cpanel" />
                <h3>Free domains</h3>
                <p>Get a free TLD domain name of your <br /> choice with every hosting pack*.</p>
            </div>
            <div className="col-md text-center">
                <img className="img-mng" src={https} alt="arab hosting cpanel" />
                <h3>Free SSL</h3>
                <p>All hosting packages come with free SSL certificate pre-installed.</p>
            </div>
        </div>
        <div className="row mt-6">
            <div className="col-md home-cntnt-cards">
                <h3>Starting at $4.5/month</h3>
                <p>ARABHOST's hosting and web services are affordable for most budgets types and sizes.</p>
                <Link to="/hosting#top" className="btn btn-lg btn-outline-primary" href="hosting-registration-step-1.php">
                    Get started
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="header-left-btn-row" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                    </svg>
                </Link>
            </div>
            <div className="col-md home-cntnt-cards">
                <h3>Included in All Packages</h3>
                <ul>
                    <li>Free Domain Name</li>
                    <li>Free SSL Certificate</li>
                    <li>Easy to Use Multi Languages Control Panel</li>
                    <li>Wordpress, Opencart, SMF, Node.js, Python, etc.</li>
                </ul>
            </div>

        </div>
        <div className="row mt-5 hosting-steps">
            <div className="col-md-7">
                <h3>Registration Steps</h3>
                <p>Starting a web project is very easy process with ARABHOST, no need for storing sensitive data, passwords or credit cards details, it is just like counting 1, 2 and 3:</p>
                <ol>
                    <li>Choose a hosting pack and domain name.</li>
                    <li>Pay using PayPal or Credit Card.</li>
                    <li>Receive registration details by email and start working on your project!</li>
                </ol>
                <Link to="/hosting#top" className="btn btn-lg btn-outline-primary" href="hosting-registration-step-1.php">
                    Get started
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="header-left-btn-row" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                    </svg>
                </Link>
            </div>
            <div className="col-md-5">
                <img src={developer} alt="ARABHOST hosting steps" />
            </div>
        </div>
    </div>
  )
}

export default Contents
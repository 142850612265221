import { Link, NavLink } from 'react-router-dom';
import logo from '../images/arabhost_logo.png'

function NavBar() {

  // const isActive = {
  //   color: 'red'
  // };

  return (
    <nav className="navbar navbar-expand-md bg-dark-ah">
        <div className="container-fluid">
            <Link to='./' className="navbar-brand">
            <img src={logo} alt="arab web hosting services" className="logo d-inline-block align-text-top" />
            </Link> 
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
            </button>
        <div className="collapse navbar-collapse ms-5 d-flex justify-content-center" id="navbarNavAltMarkup">
            <div className="navbar-nav mr-auto">
                <Link to='/' className="nav-link active" aria-current="page">Home</Link>
                <NavLink to='/hosting' className="nav-link" activeClassName="active">Hosting</NavLink>
                <Link to='/servers' className="nav-link">Servers</Link>
                <Link to='/webdesign' className="nav-link">Web design</Link>
                <Link to='/support' className="nav-link">Support</Link>

        </div>
        </div>
        <div className="nav-login-wrapper">
        <Link to='./UnderMaintenance' className="btn btn-sm btn-outline-secondary my-sm-0">
            عربي</Link>
        </div>
        </div>
    </nav>
  )
}

export default NavBar;
import Footer from "./Footer";

function UnderMaintenance() {
  return (
    <>
    <div id="top" className="container-fluid">
        <div className="row bg-wave">
        </div>
    </div>
    
    <div className="d-block vh-100 h1 text-center" style={{direction: 'rtl'}}>قيد الإنشاء...</div>

    <Footer />
    </>
  )
}

export default UnderMaintenance
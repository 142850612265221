// Bootstrap: npm i bootstrap
// import
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';

// Sass: npm i sass
import './scss/custom.scss';

// Components
import NavBar from './comps/NavBar';
// import { Home, Hosting, Support } from './comps/';

import Home from './Home';
import Hosting from './Hosting';
import Servers from './Servers';
import WebDesign from './WebDesign';
import Support from './Support';
import UnderMaintenance from './UnderMaintenance';

import { Routes, Route} from "react-router-dom";


// Global pages title
document.title = "ARABHOST | استضافة العرب";

function App() {
  return (
    <>
    <NavBar />
    <Routes>
        <Route path='/' index element={ <Home />} />
        <Route path='/Hosting' element={ <Hosting />} />
        <Route path='/Servers' element={ <Servers />} />
        <Route path='/WebDesign' element={ <WebDesign />} />
        <Route path='/Support' element={ <Support />} />
        <Route path='/UnderMaintenance' element={ <UnderMaintenance />} />

    </Routes>
    
    </>
  );
}

export default App;


import rightsvg from './images/header_right_sec.svg';
// import { HashLink as Link } from 'react-router-hash-link'; // incase of using #anchor to top page
import { Link } from 'react-router-dom';

function Header() {
  return (
    <>
    <div className="container-fluid bg-dark-ah header-ah">
        <div className="row">
            <div className="col-md header-left">
                <div className="header-left-promo">
                    <h1>High Quality Web Hosting for Low Cost</h1>
                    <h2>Get a free domain name with every hosting pack*</h2>

                    <Link to="/hosting" className="btn btn-lg btn-outline-primary" href="hosting-registration-step-1.php">
                        Register your website now
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="header-left-btn-row" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                        </svg>
                    </Link>
                    
                </div>
            </div>
            <div className="col-md header-right d-flex justify-content-center">
                <img src={rightsvg} alt="arabhost" />
            </div>
            
        </div>

    </div>
    <div className="container-fluid">
        <div className="row bg-wave">
        </div>
    </div>
    </>
  )
}

export default Header
import Footer from "./Footer";

function Support() {
  return (
    <>
    <div id="top" className="container-fluid">
        <div className="row bg-wave">
        </div>
    </div>

    <div style={{display: 'block', height: 400, textAlign: 'center'}}><h1>Support..</h1></div>
    <br></br>
    
    <Footer />

    </>
  )
}

export default Support
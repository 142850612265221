import Banner from "./Banner";
import Contents from "./Contents";
import Footer from "./Footer";

function Home() {
  return (
    <>
    <Banner />
    <Contents />
    <Footer />
    
    </>
  )
}

export default Home
import Footer from "./Footer";

function WebDesign() {
  return (
    <>
    <div id="top" className="container-fluid">
        <div className="row bg-wave">
        </div>
    </div>

    <div className="d-block vh-100 h1 text-center">Web Design..
    </div>
    
    <Footer />
    </>

  )
}

export default WebDesign
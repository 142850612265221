// importing images from /images
import logo2 from './images/logo_2.png';
import phone from './images/telephone.svg';
import email from './images/envelope-at.svg';
import ilink from './images/ilink.svg';
import facebook from './images/facebook.svg';
import whatsapp from './images/whatsapp.svg';
import youtube from './images/youtube.svg';

// import { Link } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';

function Footer() {
  return (
        <>

        <div className="container-fluid">
            <div className="row bg-wave-footer"></div>
        </div>

        <div className="container-fluid bg-footer">

        <footer className="footer">

        <div className="row">
            <div className="col-md-6 footer-about">
                <img className="logo_2_footer" src={logo2} alt="arabhost hosting and web service" />
                <h4 className='d-inline-block'>ARABHOST.COM</h4>
                <p>Since 1999 we provide high quality and affordable web hosting services for the web community in the middleeast and the rest of the world, ARABHOST is well known web provider and our services are highly recommended.</p>

        </div>
            
            <div className="col-md-3 footer_links">
                <h4>Links</h4>
                <ul>
                <li><Link to='/hosting#top'>Hosting</Link></li>
                <li><Link to='/servers#top'>Servers</Link></li>
                <li><Link to='/webdesign#top'>Web Design</Link></li>
                <li><Link to='/support#top'>Support</Link></li>
                </ul>
            </div>

            <div className="col-md footer-contacts">
                <h4>Contacts</h4>
                <div className='footer_contact_info'>
                    <p> <img src={phone} alt="Contact arabhost for support" height="20" />&nbsp;&nbsp; +961 78885067</p>
                    <p> <img src={email} alt="Contact arabhost for support" height="20" />&nbsp;&nbsp; info@arabhost.com</p>
                </div>

                <div className="footer_contact_social">
                <img src={ilink} alt="" height="26" style={{marginLeft: -2}} />&nbsp;&nbsp;&nbsp;
                <img src={whatsapp} alt="" height="26" />&nbsp;&nbsp;&nbsp;&nbsp;
                <img src={facebook} alt="" height="26" />&nbsp;&nbsp;&nbsp;&nbsp;
                <img src={youtube} alt="" height="26" />
                </div>
            </div>
            
        </div>
        </footer>
        </div>
        </>
    
  )
}

export default Footer